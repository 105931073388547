import { getHostName } from './common.utils';

export function load_js_css_file(url, id, file_type) {
  return new Promise((resolve, reject) => {
    if (id && document.getElementById(id)) {
      resolve();
      return;
    }
    let element;

    if (file_type === 'js') {
      element = document.createElement('script');
      element.setAttribute('type', 'text/javascript');
      element.setAttribute('src', url);
    }
    else if (file_type === 'css') {
      element = document.createElement('link');
      element.setAttribute('rel', 'stylesheet');
      element.setAttribute('type', 'text/css');
      element.setAttribute('href', url);
    }

    if (typeof element != 'undefined') {
      element.id = id;
      document.getElementsByTagName('head')[0].appendChild(element);
      element.onload = resolve;
      element.onerror = reject;
    }
  });
}

export function loadScript(url, onload = () => {}, id = null) {
  if (id && document.getElementById(id))
    return onload();

  const scriptTag = document.createElement('script');
  scriptTag.setAttribute('src', url);
  scriptTag.id = id;
  document.head.appendChild(scriptTag);
  scriptTag.onload = onload;
}

export function loadScriptWithContents(contents, id = null, onload = () => {}) {
  if (id && document.getElementById(id))
    return onload();

  const scriptTag = document.createElement('script');
  scriptTag.innerHTML = contents;
  scriptTag.id = id;
  scriptTag.onload = onload;
  document.head.appendChild(scriptTag);
}

export function loadMarker() {
  if (getHostName() !== 'localhost')
    loadScriptWithContents('!(function (e, r, a) {  if (!e.__Marker) { e.__Marker = {}; const t = []; const n = { __cs: t }; [\'show\', \'hide\', \'isVisible\', \'capture\', \'cancelCapture\', \'unload\', \'reload\', \'isExtensionInstalled\', \'setReporter\', \'setCustomData\', \'on\', \'off\'].forEach((e) => { n[e] = function () { const r = Array.prototype.slice.call(arguments); r.unshift(e), t.push(r) } }); const s = r.createElement(\'script\'); s.async = 1, s.src = \'https://edge.marker.io/latest/shim.js\'; const i = r.getElementsByTagName(\'script\')[0]; i.parentNode.insertBefore(s, i) };  }(window, document))', 'markerio-js');
}

export function loadChargeBee() {
  loadScript('https://js.chargebee.com/v2/chargebee.js', () => {
    // eslint-disable-next-line no-undef
    Chargebee.init({
      site: 'taskmapper-test',
      publishableKey: 'test_Db3AJUoPTkSRX2fRFnsVk3r0zouSgSKZ',
    });
  }, 'chargebee-js');
}

export function loadAnalytics() {
  const analytics = window.analytics = window.analytics || [];
  if (!analytics.initialize)
    if (analytics.invoked) {
      logger?.error?.('Segment snippet included twice.');
    }
    else {
      analytics.invoked = !0;
      analytics.methods = ['trackSubmit', 'trackClick', 'trackLink', 'trackForm', 'pageview', 'identify', 'reset', 'group', 'track', 'ready', 'alias', 'debug', 'page', 'once', 'off', 'on', 'addSourceMiddleware', 'addIntegrationMiddleware', 'setAnonymousId', 'addDestinationMiddleware'];
      analytics.factory = function (e) {
        return function () {
          // eslint-disable-next-line prefer-rest-params
          const t = Array.prototype.slice.call(arguments);
          t.unshift(e);
          analytics.push(t);
          return analytics;
        };
      };
      for (const method of analytics.methods)
        analytics[method] = analytics.factory(method);
      analytics.load = function (key, e) {
        const t = document.createElement('script');
        t.type = 'text/javascript';
        t.async = !0;
        t.src = `https://cdn.segment.com/analytics.js/v1/${key}/analytics.min.js`;
        const n = document.getElementsByTagName('script')[0];
        n.parentNode.insertBefore(t, n);
        analytics._loadOptions = e;
      };
      analytics._writeKey = '<%= VITE_APP_SEGMENT_WRITE_KEY %>';
      analytics.SNIPPET_VERSION = '4.15.3';
      analytics.load(import.meta.env.VITE_APP_SEGMENT_WRITE_KEY);
    }
}
export function loadAtlas() {
  let t;
  const e = {
    appId: 'ux37fvhb2z',
    v: 2,
    q: [],
    call() {
      // eslint-disable-next-line prefer-rest-params
      this.q.push(arguments);
    },
  };

  window.Atlas = e;

  const n = document.createElement('script');
  n.async = true;
  n.src = 'https://app.atlas.so/client-js/atlas.bundle.js';

  const s = document.getElementsByTagName('script')[0];
  // eslint-disable-next-line prefer-const
  t = s.parentNode;

  if (t !== null && t !== undefined)
    t.insertBefore(n, s);
}

export function startAtlas() {
  if (window.Atlas)
    window.Atlas.call('start', {
      chat: {
        hideBubble: true,
      },
    });
  else
    logger.warn('Atlas is not initialized yet.');
}
