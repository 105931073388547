<script setup>
import { cloneDeep, findIndex, startsWith, trim, trimStart } from 'lodash-es';

import { storeToRefs } from 'pinia';

import { convertTableUnitsData } from '~/common/utils/common.utils.js';
import { useDashboardScheduleStore } from '~/dashboard/store/dashboard-schedule.store.js';
import { useDashboardStore } from '~/dashboard/store/dashboard.store.js';

import HawkTable from '~/common/components/organisms/hawk-table/hawk-table.vue';

const props = defineProps({
  data: {
    type: Object,
  },
  id: {
    type: String,
  },
  content_height: {
    type: Number,
  },
  response_data: {
    type: Object,
  },
  payload: {
    type: Object,
  },
  graph_config: {},
});
const $services = inject('$services');
const loading = ref(false);
const activities = ref([]);
const columns = ref([]);
const children = ref({});
const dashboard_schedule_store = useDashboardScheduleStore();
const dashboard_store = useDashboardStore();
const activity_key_map = ref(null);
const no_data = ref(false);
const columns_ref = ref('');
const prevent_watcher = ref(false);
const print_dataset = ref([]);

const widget_data = computed(() => props.data?.data || {});

const {
  schedule_configuration,
} = storeToRefs(dashboard_schedule_store);

const column_key_map = {
  actual: '% Actual',
  actual_units: 'Actual',
  lag: '% Lag',
  lag_units: 'Lag',
  planned: '% Planned',
  planned_units: 'Planned',
  remaining: '% Remaining',
  remaining_units: 'Remaining',
  total_units: 'Total (Scope)',

};

async function getReports() {
  loading.value = true;

  activity_key_map.value = props.payload?.properties.activity_table_activities;
  try {
    const data = props.response_data;
    if (data && Object.keys(data)) {
      no_data.value = false;
      activities.value = generateActivities(data.data);
      print_dataset.value = [...activities.value];

      generateColumns(widget_data.value.columns, Object.values(data.data)[0]);
    }
    else {
      no_data.value = true;
      activities.value = [];
      print_dataset.value = [];
      columns.value = [];
      loading.value = false;
    }
    loading.value = false;
  }
  catch (err) {
    loading.value = false;
  }
}

function generateActivities(data) {
  const array = [];
  for (const [key, value] of Object.entries(data)) {
    const current_item = {};
    if (['PROJECT', 'WBS'].includes(value.type))

      current_item.subRows = [''];

    current_item.activity_id = key;
    const field_names = ['uid', 'id', 'activity_id', 'type', 'activity', 'actual_duration', 'actual_progress', 'critical', 'lag', 'lag_days', 'planned_duration', 'planned_progress', 'remaining_duration', 'remaining_progress', 'status', 'bl_start', 'bl_finish', 'planned_start', 'planned_finish', 'actual_start', 'actual_finish', 'duration', 'free_slack', 'total_slack', 'overdue_days', 'name', 'absolute_remaining_duration', 'absolute_work_rate', 'planned_work_rate', 'actual_work_rate', 'bl_work_rate', 'remaining_work_rate'];
    for (const [nestedKey, nestedValue] of Object.entries(value))
      if (nestedKey && !field_names.includes(nestedKey))
        Object.keys(nestedValue.values).forEach((valueKey) => {
          current_item[`${valueKey}-${nestedKey}`] = convertTableUnitsData(nestedValue.values[valueKey], nestedValue.units);
        });
      else if (nestedKey === 'name')
        current_item.name = activity_key_map.value?.find(e => e.uid === key)?.name || nestedValue;

      else if (nestedKey === 'id')
        current_item.id = nestedValue;
      else if (nestedKey === 'absolute_remaining_duration')
        current_item[nestedKey] = value.status === 'completed' ? '-' : startsWith(nestedValue, '-') ? `${trim(nestedValue, '-')} days overdue` : `${nestedValue} days remaining`;

      else
        current_item[nestedKey] = convertTableUnitsData(nestedValue, null);

    array.push(current_item);
  }

  return array;
}

const columns_widths_map = computed(() => widget_data.value.columns_widths || {});

function generateColumns(data, response_data) {
  const column_array = [{
    accessorKey: 'name',
    id: 'name',
    header: 'Activity',
    size: columns_widths_map.value?.name || '400',
  }];
  data.forEach((item) => {
    let header_name = '';
    if (item.name === 'id')
      header_name = 'Activity id';
    else if (item.key && response_data?.[item.key]?.units)
      header_name = `${item.name} (${item.key && response_data?.[item.key]?.units})`;
    else
      header_name = item.name;

    column_array.push({
      accessorKey: item.key,
      header: header_name,
      id: item.key,
      cell: info => `${trimStart(info.getValue(), '-')} `,
      ...(item?.column_types?.length
        ? {
            columns: item.column_types.map((child) => {
              const col_id = `${child}-${item.key}`;
              return {
                accessorKey: col_id,
                id: col_id,
                header: child === 'id' ? 'Activity id' : column_key_map[child],
                size: columns_widths_map.value?.[col_id] || '150',
              };
            },
            ),
          }
        : {
            size: columns_widths_map.value?.[item.key] || '150',
          }),
    });
  },
  );
  columns.value = [];
  columns.value = [...column_array];
}

async function rowExpanded(e) {
  const payload_copy = cloneDeep(props.payload);
  payload_copy.filters = { ...payload_copy.filters, advanced_filters: [{ field: 'parent', operator: 'is', value: e.row.id }] };
  payload_copy.data.activities = [];

  const { data } = await $services.project_management.get_graph({ body: payload_copy });
  const child = [];
  child.push(...generateActivities(data.data));

  if (!children.value[e.row.id] && child.length) {
    children.value[e.row.id] = child;
    forceUpdate();
    updatePrintDataset(e.row.id, child);
    updatePrintMap();
  }
}

function forceUpdate() {
  const copy = markRaw(activities.value);
  activities.value = [...copy];
}
function getChildren(row) {
  if (row?.subRows?.length)
    if (children.value[row.id]?.length)
      return children.value[row.id];
    else
      return row?.subRows;
}

function updatePrintDataset(parent_id, children) {
  if (!children?.length)
    return;

  const index = findIndex(print_dataset.value, a => a.id === parent_id);
  print_dataset.value.splice(index + 1, 0, ...children);
}

const status_color = {
  'On track': 'blue',
  'Delayed': 'red',
  'Completed': 'green',
  'Not Started': 'gray',
};

const height = computed(() => {
  return ((props.data.h || 22) * 20) - 44;
});

function formatTable(cell) {
  // this is failing when expanding rows, might be the issue for the flickering
  if (startsWith(cell.getValue(), '-'))
    return { color: '#DC2626' };
  return {};
}

function updatePrintMap() {
  dashboard_store.update_print_map(props.id, {
    type: widget_data.value.type,
    renderAt: `chart-container-${props?.id}`,
    renderType: props.data.data.activity_table_chart ? 'combined_table_graph' : 'table',
    width: '100%',
    height: '100%',
    dataFormat: 'json',
    chart_name: widget_data.value.name,
    dimensions: {
      x: props.data.x,
      y: props.data.y,
    },
    dataSource: {
      columns: columns.value,
      activities: print_dataset.value,
      dataset: print_dataset.value,
      is_transpose: widget_data.value.transpose === true,
      dashboard_index: props.data.i,
      is_new_pivot_chart: widget_data.value.chart === 'workflow_pivot_table',
      ...(props.data.data.activity_table_chart ? { graph_config: props.graph_config } : {}),
    },
  });
}

function columnResized(_resized_column, columns_widths) {
  // prevents the table from rerendering
  prevent_watcher.value = true;
  dashboard_store.set_table_column_widths(
    props?.id,
    columns_widths,
  );
}

const force_update = ref(0);
watch(() => props.response_data, async (new_val, old_val) => {
  if (!prevent_watcher.value) {
    await getReports();
    if (props?.id !== 'preview')
      updatePrintMap();
    force_update.value++;
  }
}, { immediate: true, deep: true });
</script>

<template>
  <div>
    <div v-if="no_data || !activities?.length" class="text-sm font-semiBold w-full" :class="dashboard_store.is_mobile_view ? 'h-[240px] grid place-items-center' : 'mt-8 flex justify-center'">
      {{ $t('No data present') }}
    </div>
    <a v-if="activities && columns?.length && activities?.length ">
      <div class="w-full scrollbar" :style="{ height: `${content_height || height}px` }">
        <HawkTable
          :key="force_update"
          :data="activities"
          :columns="columns"
          freeze_column_id="name"
          :show_menu_header="false"
          :show_column_borders="true"
          :format-table="formatTable"
          additional_table_classes=""
          :container_class="`h-[${content_height || height}px]`"
          :striped="true"
          is_gapless
          :get-children="getChildren"
          :disable_resize="!dashboard_store.is_editing_dashboard"
          cell_height="30px"
          @sub-rows="rowExpanded($event)"
          @column-resized="columnResized"
        >
          <template #status="status">
            <HawkBadge :color="status_color[status.data.getValue()]">
              {{ status.data.getValue() }}
            </HawkBadge>
          </template>
        </HawkTable>
      </div>
    </a>
  </div>
</template>
