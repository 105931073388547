<script setup>
import { cloneDeep, isEqual } from 'lodash-es';
import { computed, ref } from 'vue';
import { useModal } from 'vue-final-modal';
import Papa from 'papaparse';
import useAbortController from '~/common/composables/abort-controller.js';
import { load_js_css_file } from '~/common/utils/load-script.util';
import { useInventoryStore } from '~/inventory/store/inventory.store';
import InventoryInvalidStockAlert from '~/inventory/components/inventory-transaction-form/inventory-invalid-stock-alert.vue';

import { useCommonImports } from '~/common/composables/common-imports.composable.js';

const props = defineProps({
  value: {
    type: Array,
    default: () => [],
  },
  id: {
    type: String,
    default: 'data_table',
  },
  is_batch_number: {
    type: Boolean,
    default: false,
  },
  quantity: {
    type: Number,
    default: 0,
  },
  populate_batch_numbers: {
    type: Boolean,
    default: false,
  },
  initial_invalid_array: {
    type: Array,
    default: () => [],
  },
  item: {
    type: Object,
    default: null,
  },
  line: {
    type: Object,
    default: null,
  },
  workflow: {
    type: Object,
    default: null,
  },
  location: {
    type: String,
    default: '',
  },
});
const { $services, $toast, route } = useCommonImports();

const controllers = useAbortController();
const inventory_store = useInventoryStore();
const file_upload_input = ref(null);
const form$ = ref(null);
const state = reactive({
  has_errors: false,
  invalid_items_map: {},
  track_by: {
    label: 'Serial/Pallet number',
    uid: 'serial_no',
  },
  batch_set: new Set(),
  loading: false,
  signal: null,
  open_context_menu: false,
  sn_data: [],
  sn_columns: [
    {
      data: 'batch_number',
      text: 'Pallet number',
    },
    {
      data: 'serial_no',
      text: 'Serial number',
    },

  ],
});

const invalid_items = computed(() => {
  return Object.keys(state.invalid_items_map).filter(key => state.invalid_items_map[key]);
});
const is_not_on_system = computed(() => {
  return !props.workflow?.from_status?.length;
});
const item_custom_fields = computed(() => {
  return inventory_store.get_custom_fields({
    uid: props.item.uid,
  }).filter(field => field.type === 'text' || field.type === 'number');
});
const item_types = computed(() => {
  const fields = [{ uid: 'serial_no', label: 'Serial/Pallet number' }];
  item_custom_fields.value.forEach((field) => {
    fields.push({ uid: field.uid, label: field.name });
  });
  return fields;
});

const show_context_menu = computed(() => {
  return Boolean(!is_not_on_system.value && item_custom_fields.value.length);
});
const data_table = ref(null);

const { open: openInvalidStockAlert, close: closeInvalidStockAlert, patchOptions } = useModal({
  component: InventoryInvalidStockAlert,
});

function get_filtered_serial_numbers() {
  return state.sn_data.map((serial_number) => {
    const batch_serial_no = serial_number.batch_number ? serial_number.serial_no : false;
    return props.is_batch_number ? batch_serial_no : serial_number.serial_no;
  }).filter(sn => sn);
}

async function getValue(format) {
  const data = data_table.value.getData().reduce((result, curr) => {
    if (props.is_batch_number && is_not_on_system.value) {
      if ((curr[0] ?? false) && (curr[1] ?? false))
        result.push({
          batch_number: curr[0],
          serial_no: curr[1],
        });
    }
    else if (curr[0] ?? false) {
      result.push({
        serial_no: curr[0],
      });
    }

    return result;
  }, []);
  if (format)

    if (format === 'default')
      return {
        serial_numbers: data.map(item => ({ ...item, custom_fields: [] })),
      };

  return {
    serial_numbers: data.map(item => ({ ...item, custom_fields: [] })),
  };
}

function has_duplicates() {
  const sn_set = new Set();
  let has_error = false;
  const data = data_table.value.getData();
  data.forEach((row) => {
    if (props.is_batch_number) {
      if ((row[0] ?? false) && (row[1] ?? false))
        if (sn_set.has(row[1])) {
          state.invalid_items_map[row[1]] = true;
          has_error = true;
        }
        else { sn_set.add(row[1]); }
    }
    else {
      if (row[0] ?? false)
        if (sn_set.has(row[0])) {
          state.invalid_items_map[row[0]] = true;
          has_error = true;
        }
        else { sn_set.add(row[0]); }
    }
  });

  validate_table();
  return has_error;
}

onMounted(async () => {
  state.signal = controllers.add('sn_input', false);
  add_invalid_numbers(props.initial_invalid_array);
  await load_js_css_file(
    'https://cdn.jsdelivr.net/npm/handsontable@12.2.0/dist/handsontable.full.min.js',
    'handsontable-js',
    'js',
  );
  await load_js_css_file(
    'https://cdn.jsdelivr.net/npm/handsontable@12.2.0/dist/handsontable.full.min.css',
    'handsontable-css',
    'css',
  );

  if (props.value?.data)
    state.sn_data = cloneDeep(props.value.data);

  else state.sn_data.push({ serial_no: '' });
  if (props.value?.columns && is_not_on_system.value)
    state.sn_columns = cloneDeep(props.value.columns);

  await makeDataTable();

  if (props.is_batch_number)
    data_table.value.getDataAtCol(0).forEach((value) => {
      if (value)
        state.batch_set.add(value);
    });
  if (props.line?.stock_input?.field_uid) {
    state.track_by = props.line?.stock_input?.data?.track_by;
    state.sn_columns[0].text = props.line?.stock_input?.data?.track_by?.label;
    data_table.value.updateSettings({

      columns: state.sn_columns,
    });
  }

  await validate_table();
});

async function makeDataTable() {
  const container = document.getElementById(props.id);
  if (!is_not_on_system.value || !props.item.is_batch_number)
    state.sn_columns = state.sn_columns.filter(item => item.data !== 'batch_number');
  if (!is_not_on_system.value && props.item.is_batch_number)
    state.sn_columns[0].text = 'Serial/Pallet number';

  data_table.value = new Handsontable(container, {
    colHeaders(index) {
      return state.sn_columns[index].text;
    },
    rowHeaders: true,
    licenseKey: import.meta.env.VITE_APP_HOT_LICENSE_KEY,
    autoRowSize: false,
    minRows: props.quantity || 10,
    maxRows: props.quantity || 20000,
    stretchH: 'all',
    data: state.sn_data,
    rowHeights: 26,
    height: '25rem',
    width: '100%',
    copyPaste: true,
    viewportRowRenderingOffset: 100,
    columns: state.sn_columns,

    async afterChange(changes, source) {
      if (source !== 'loadData' && changes) {
        changes.forEach((change) => {
          if (change[1] === 'serial_no')
            state.invalid_items_map[change[2]] = false;
        });

        if (changes.find(change => change[1] === 'batch_number')) {
          state.batch_set = new Set();
          data_table.value.getDataAtCol(0).forEach((value) => {
            if (value)
              state.batch_set.add(value);
          });
        }

        if (state.sn_data[state.sn_data.length - 1]?.serial_no) {
          state.sn_data.push({});
          data_table.value.updateData(state.sn_data);
          reRenderDataTable();
        }

        state.has_errors = false;
      }
      state.loading = false;
    },
    beforePaste(data, coords) {
      state.loading = true;
      const amountOfChangedCells = data.length;
      const startingPoint = coords[0].startRow;
      const numberOfRows = data_table.value.countRows();

      if (numberOfRows < amountOfChangedCells + startingPoint)
        data_table.value.alter('insert_row_below', amountOfChangedCells + startingPoint, amountOfChangedCells - (numberOfRows - startingPoint));

      const new_data = data.map((line) => {
        return line.map(value => value.replaceAll('\n', ''));
      });

      if (isEqual(data, new_data))
        return true;

      const copyPastePlugin = data_table.value.getPlugin('copyPaste');
      copyPastePlugin.paste(new_data);

      return false;
    },

  });

  data_table.value.render();
}

async function reRenderDataTable() {
  await data_table.value?.render();
}

function add_invalid_numbers(numbers = []) {
  numbers.forEach((number) => {
    state.invalid_items_map[number] = true;
  });
  validate_table();
}

async function validate_table() {
  return new Promise((resolve) => {
    data_table?.value?.validateCells((valid) => {
      state.has_errors = !valid;
      resolve(!valid);
    });
  });
}
function updateTableSettings(options = {}) {
  if (data_table.value)
    data_table.value.updateSettings({
      data: state.sn_data,
      columns: state.sn_columns,
    });
}
function changeTracking(value) {
  state.track_by = value;

  state.sn_columns = [{
    data: 'serial_no',
    text: value.label,
  }];
  data_table.value.updateSettings({
    columns: state.sn_columns,
    data: state.sn_data.map(item => ({ serial_no: null })),
  });
}
async function parseCSVFile(file) {
  return new Promise((resolve) => {
    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      transformHeader(value) {
        return value.trim(); // Removes leading and trailing spaces
      },
      complete: (results) => {
        resolve(results.data);
      },
    });
  });
}

async function importHandler() {
  try {
    const file = file_upload_input.value.files[0];
    const result = await parseCSVFile(file);
    const ordered_columns = [];
    const first_index_in_data_array = result?.[0] || {};
    const data = [];
    const fixedStrings = ['pallet number', 'serial number'];
    Object.keys(first_index_in_data_array).forEach((key) => {
      if (key.length && key !== '_1')
        if (!ordered_columns.includes(key))
          if (key.toLowerCase() === 'serial number')
            ordered_columns.unshift({
              data: 'serial_no',
              text: 'Serial number',
              type: 'text',
              original_text: key,
              className: 'htLeft htMiddle',
            });

          else if (key.toLowerCase() === 'pallet number')
            ordered_columns.unshift({
              data: 'batch_number',
              text: 'Pallet number',
              type: 'text',
              original_text: key,
              className: 'htLeft htMiddle',
            });

          else
            ordered_columns.push({
              data: key,
              text: key,
              type: 'text',
              className: 'htLeft htMiddle',
            });
    });
    const startItems = fixedStrings.map(item => ordered_columns.find(element => element?.text?.toLowerCase() === item));
    const remainingItems = ordered_columns.filter(item => !fixedStrings.includes(item.text?.toLowerCase()));
    const final_columns = [...startItems, ...remainingItems].filter(item => item);
    const serial_number_text = ordered_columns.find(item => item.data === 'serial_no')?.original_text;
    const pallet_number_text = ordered_columns.find(item => item.data === 'batch_number')?.original_text;
    result.forEach((item) => {
      data.push({
        ...item,
        serial_no: item[serial_number_text],
        ...(pallet_number_text && { batch_number: item[pallet_number_text] }),
      });
    });

    state.sn_data = data;

    state.sn_columns = final_columns;
    updateTableSettings();
  }
  catch (error) {
    $toast({
      title: 'Error',
      text: 'Please check your file something went wrong.',
      type: 'error',
      position: 'top-right',
    });
    logger.error(error);
  }
};

onBeforeUnmount(() => {
  controllers.abortAll();
});

defineExpose({ getValue, add_invalid_numbers, state });
</script>

<template>
  <div>
    <input
      id="fileUploader"
      ref="file_upload_input"
      type="file"
      style="display: none"
      accept=".csv"
      @change="importHandler"
    >
    <HawkButton
      v-if="is_not_on_system"
      class="ml-auto !flex mt-[-40px]"
      type="link"
      :loading="state.importing"
      @click="file_upload_input?.click()"
    >
      <IconHawkImport class="w-3 h-3" />
      {{ 'Import file' }}
    </HawkButton>
    <div class="z-[999] w-max mb-1 !flex ml-auto">
      <HawkMenu
        v-if="show_context_menu"
        :items="item_types"

        position="fixed"

        @open="state.open_context_menu = true"
        @close="state.open_context_menu = false"
        @select="changeTracking"
      >
        <template #trigger>
          <div
            class=" text-sm flex items-center"
          >
            {{ state.track_by?.label }}
            <IconHawkChevronUp v-if="state.open_context_menu" class="text-lg" />
            <IconHawkChevronDown v-else class="text-lg" />
          </div>
        </template>
      </HawkMenu>
    </div>

    <div class="flex-col flex">
      <div class="flex">
        <div :id="id" />
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
:deep(.htCore) {
  th {
    vertical-align: middle;
    padding: 4px;
    font-weight: 500;
    text-align: left;
    height: 25px !important;
    background-color: rgba(249, 250, 251, 1);
    &:nth-child(1) {
      text-align: center;
    }
  }
  td {
    padding: 4px;

    cursor: pointer;
    max-width: 30rem;
  }
}
</style>
